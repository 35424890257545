import { Autocomplete } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs, locale } from "dayjs";
import { date } from "zod";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import InputField from "../../../../components/Field";
import { State } from "@hookstate/core";
import { useEffect, useState } from "react";
import { getAllCompanyBranches } from "../../services/company-brach";

interface IFiltersSchedulingTab {
  readonly date: State<Dayjs>;
  readonly locale: State<{ id: number; name: string } | null>;
  readonly value: State<number>;
}

export default function FiltersSchedulingTab({
  date,
  locale,
  value,
}: IFiltersSchedulingTab) {
  const [companyBranches, setCompanyBranches] = useState<{id: number; name: string;}[]>([]);

  useEffect( ()=> {
    console.log(getAllCompanyBranches())
      getAllCompanyBranches()
        .then((companies) => setCompanyBranches(companies.map((company) => ({
          id: company.id, 
          name: company.company_branch_name
          })) )).catch( error => console.log(error))
  }, [])

  return (
    <Grid container display={"flex"} sx={{ gap: 1 }} size={12}>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={"pt-br"}
        >
          <DateTimePicker
            label="Data do Agendamento"
            format="DD/MM/YYYY"
            value={date.get()}
            minDate={dayjs()}
            onChange={(value) => date.set(value ? value : dayjs())}
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small",
                sx: {
                  mt: 0,
                  borderRadius: "50%",
                  "& .MuiInputBase-root": {
                    border: "0.2px",
                    borderRadius: "10px",
                    "& input": {
                      padding: 1,
                    },
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Autocomplete
          id="filter-location"
          options={companyBranches}
          value={locale.get()}
          noOptionsText={"Sem opção"}
          size="small"
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          onChange={(_, newLocale) => locale.set(newLocale)}
          renderInput={(params) => (
            <InputField {...params} label={"Local do Agendamento"} />
          )}
          fullWidth
        />
      </Grid>
      <Grid
        container
        size={12}
        sx={{ gap: 1 }}
        display={"flex"}
        justifyContent="flex-end"
        paddingTop={2}
      >
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton
            stepMode
            onConfirmClick={() => value.set(value.get() + 1)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
